import React from "react";
import Button, { HalfButton2 } from "./button";

const BenefitSingle = ({ feature, children, isTextSideRight = true, lang }) => {
  console.log("feature", feature)
  const WhichButton = feature.priceButton ? HalfButton2 : Button;
  const addLeftClass = originalClass => {
    if (isTextSideRight === true && originalClass === "sideImg") {
      return originalClass + " left";
    } else if (isTextSideRight === false && originalClass === "benefit") {
      return originalClass + " left";
    }
    return originalClass + " right";
  };
  const addBlackButton = (originalClass, ctaBG) => {
    if (ctaBG) {
      return originalClass + " " + ctaBG;
    }
    return originalClass;
  };
  const addArrowToButton = (originalClass, ctaArrow) => {
    if (ctaArrow) {
      return originalClass + " withArrow";
    }
    return originalClass;
  };
  return (
    <div className="benefitRow">
      <div
        className={addLeftClass("sideImg")}
        style={{ backgroundImage: `url("${feature.image}")` }}
      >
        {feature.video && (
          <video loop playsInline autoPlay muted>
            <source src={feature.video} type="video/mp4"></source>
          </video>
        )}
      </div>
      <div className={addLeftClass("benefit")}>
        <div className="benefitText">
          <h2>{feature.title}</h2>
          {children}
        </div>
        {feature.ctaText ? (
          <WhichButton
            className={addArrowToButton(
              addBlackButton("benefitbtn", feature.ctaBG),
              feature.ctaArrow
            )}
            link={feature.ctaLink}
            price={feature.price}
            lang={lang}
          >
            {feature.ctaText}
          </WhichButton>
        ) : (
          false
        )}
      </div>
      <div className="clearall"></div>
    </div>
  );
};

export default BenefitSingle;
